import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
      <div className="bg-transparent py-6 relative md:w-1/2">
        <button
          onClick={onClose}
          className="absolute top-0 uppercase font-raleway right-0 me-3 text-white font-bold text-2xl md:text-1xl hover:text-gray-300"
        >
          close
        </button>
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
